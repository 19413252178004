import ttv from './img/ttv.svg';
import ehtv from './img/hockeytvplus.png';

export const Watermark = ({ watermark }) => {
  const watermarks = {
    ttv: ttv,
    ehtv: ehtv,
  };
  return (
    <img
      style={{ width: '100%', mixBlendMode: 'overlay' }}
      src={watermarks[watermark]}
    />
  );
};
