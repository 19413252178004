import auth from './Authentication';
import { Session } from '../domain/Session';
import apiPath from './ApiEndpoint';

class AuthAwareAdapterProxy {
  static skipAuthentication = false;

  constructor(adapter) {
    this.adapter = adapter;

    Object.defineProperty(adapter, 'commonOptions', {
      configurable: true,
      enumerable: true,
      get: function () {
        const headers = {};
        const currentSession = Session.current();

        if (!AuthAwareAdapterProxy.skipAuthentication) {
          headers['Authorization'] = `Bearer ${auth.getAccessToken()}`;
          if (AuthAwareAdapterProxy.forceResourceGroupId) {
            headers['X-Resource-Group-Id'] =
              AuthAwareAdapterProxy.forceResourceGroupId;
          } else if (currentSession.resourceGroupId()) {
            headers['X-Resource-Group-Id'] = currentSession.resourceGroupId();
          } else {
            if (currentSession.possibleResourceGroupId()) {
              headers['X-Try-Resource-Group-Id'] =
                currentSession.possibleResourceGroupId();
            }
          }
        }
        return {
          credentials: 'omit',
          headers: headers,
        };
      },
    });
  }

  static async withSkipAuthentication(fn) {
    this.skipAuthentication = true;
    try {
      return await fn();
    } finally {
      this.skipAuthentication = false;
    }
  }

  static async withUseResourceGroup(resourceGroupId, fn) {
    if (typeof resourceGroupId === 'undefined') {
      throw 'You have to specify resource group';
    }
    this.forceResourceGroupId = resourceGroupId;
    try {
      return await fn();
    } finally {
      this.forceResourceGroupId = null;
    }
  }

  static setActiveResourceGroup(resourceGroupId) {
    this.forceResourceGroupId = resourceGroupId;
  }

  static resetActiveResourceGroup() {
    this.forceResourceGroupId = null;
  }

  authenticate() {
    if (auth.isAuthenticated || AuthAwareAdapterProxy.skipAuthentication) {
      return Promise.resolve(true);
    } else {
      return auth.auth.renewToken();
    }
  }

  _callAdapter(method, url, data, options) {
    let abortFn = () => {};

    const abort = () => {
      abortFn();
    };
    const promise = this.authenticate().then(
      () => {
        const currentSession = Session.current();
        if (currentSession.apiPath) {
          this.adapter.apiPath = currentSession.apiPath;
        }
        const { abort, promise } = this.adapter[method](url, data, options);
        abortFn = abort;
        return promise;
      },
      (e) => {
        return Promise.reject(e);
      }
    );

    return { abort, promise };
  }

  get(url, data, options) {
    return this._callAdapter('get', url, data, options);
  }

  post(url, data, options) {
    return this._callAdapter('post', url, data, options);
  }

  del(url, data, options) {
    return this._callAdapter('del', url, data, options);
  }

  put(url, data, options) {
    return this._callAdapter('put', url, data, options);
  }

  patch(url, data, options) {
    return this._callAdapter('patch', url, data, options);
  }
}

export { AuthAwareAdapterProxy };
