import React, { createContext, useContext, useEffect, useState } from 'react';
import { api } from '../../../utils/ApiClient';
import { Session } from 'domain/Session';
import { isDev } from '../../../lib/dev';
import { useQuery, useQueryClient } from 'react-query';
import { LearninglineTask } from '../components/learningline-task';
import { gotoRoute } from '../../route';

const AssignmentContext = createContext(null);

export const useAssignmentContext = () => {
  return useContext(AssignmentContext);
};

interface AssignmentVersion {
  state: string;
  created: string;
  updated: string;
  versionId: number;
  archived: boolean;
  submissionTRN: string;
  assessorUserId: string;
  submitterUserId: string;
}

interface Assignment {
  tenantId: string;
  assignmentId: string;
  taskTRN: string;
  assigneeUserId: string;
  name: string;
  state: string;
  versions: AssignmentVersion[];
  created: string;
  updated: string;
}

interface LearningLine {
  learningLineId: string;
  phases: [];
  logoSrc: string;
  name: string;
}

export const AssignmentProvider = ({
  children,
  match,
  competencies = false,
  useResourceGroupId,
}) => {
  const queryClient = useQueryClient();

  const currentSession = Session.current();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTeacher, setIsTeacher] = useState(
    currentSession.isFeatureAvailable('acceptVersion')
  );
  const [isTrainee, setIsTrainee] = useState(
    currentSession.isFeatureAvailable('submitVersion')
  );

  const [showCompetencies, setShowCompetencies] = useState(
    parseInt(match?.params?.learningLineId) ?? competencies
  );

  const [learningLineId, setLearningLineId] = useState(null);

  const [tasks, setTasks] = useState(null);

  let params = new URLSearchParams(window.location.search);
  const preview = params.get('previewId');

  useEffect(() => {
    // Remove override when context is destroyed
    return () => {
      if (useResourceGroupId) {
        currentSession.setUseResourceGroup(null);
      }
    };
  }, [useResourceGroupId]);

  if (
    useResourceGroupId &&
    currentSession.resourceGroupId() !== useResourceGroupId
  ) {
    currentSession.setUseResourceGroup(useResourceGroupId);
  }

  const { isFetching, data: assignments } = useQuery(
    ['assignments', useResourceGroupId],
    () => fetchAssignments(),
    {
      refetchOnMount: false,
      placeholderData: [],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
  const { data: assignmentFiles } = useQuery(
    ['assignments', 'files', useResourceGroupId],
    () => fetchAssignmentFileList(),
    {
      refetchOnMount: false,
      placeholderData: [],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );

  const { data: learningLines } = useQuery(
    ['learningLines'],
    () => fetchLearningLines(),
    {
      refetchOnMount: false,
      placeholderData: [],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const post = async (url, data, headers = {}) => {
    // Prevent duplicate submissions
    if (!isSubmitting) {
      setIsSubmitting(true);
      let response;
      response = await api
        .post(url, data, headers)
        .then(() => {
          return response;
        })
        .catch((e) => {
          console.log(e);
          return null;
        });
      setIsSubmitting(false);
      return response;
    }
  };

  const addVersion = async (data, assignment: Assignment) => {
    if (!assignment) {
      setIsSubmitting(true);
      assignment = await createAssignment(data.taskTRN, data.name);
      setIsSubmitting(false);
    }

    if (!assignment || !assignment.assignmentId) {
      console.log(Error('No Assignment'));
      return new Error('No Assignment');
    }
    if (!data || (!data.submissionTRN && !data.file)) {
      console.log(Error('no data or VersionTRN not set'));
      return new Error('no data or VersionTRN not set');
    }

    let _response;
    if (data.file) {
      const body = new FormData();
      body.append('comment', data?.comment);
      body.append('file', data.file);
      _response = await post(
        `/assignments/${assignment.assignmentId}/submitFileVersion`,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    } else {
      _response = await post(
        `/assignments/${assignment.assignmentId}/submitVersion`,
        {
          submissionTRN: data.submissionTRN,
          comment: data?.comment,
        }
      );
    }
    isDev() && console.log('addVersion done');
    refresh();
    return _response;
  };

  const createAssignment = async (taskTRN, name, description = '') => {
    isDev() && console.log('no assignment for task, creating..');
    const data = {
      taskTRN: taskTRN,
      name: name,
      description: description,
    };
    let _assignment;

    // Use api.post here because we do not want to mess with state of context here.
    // The function calling createAssignment provides state manegement.
    _assignment = await api
      .post('/assignments', data)
      .then((response) => response)
      .catch((e) => {
        console.log(e);
        return Error('cannot create assignment');
      });
    if (_assignment?.data) {
      return _assignment?.data;
    } else {
      return Error('cannot create assignment');
    }
  };

  const fetchAssignments = async () => {
    console.log('fetching assignments');
    const _assignments = await api.get('assignments');

    return _assignments.data;
  };

  // const fetchAssignment = async (assignmentId) => {
  //   const _assignments = await api.get(`assignments/${assignmentId}`);
  //   return _assignments.data;
  // };

  const getAssignmentByTaskID = (taskId) => {
    // Return most recent updated assignment of taskId in case of doubles having been made (which should not be possible)
    const taskAssignments = assignments?.filter((assignment) =>
      assignment.taskTRN.includes(taskId)
    );
    // It might happen due to a bug in the backend that we end up with multiple assignments for the same
    // task. Always pick the last one.
    return (
      taskAssignments.sort((a, b) => b.updated.localeCompare(a.updated))[0] ??
      null
    );
  };

  const fetchAssignmentFileList = async () => {
    const files = await api.get('/assignments-files');

    return files.data;
  };

  const fetchLearningLines = async () => {
    if (preview) {
      const response = await api.get('/learningLines/preview', {
        params: {
          previewId: preview,
        },
      });
      return response.data;
    } else {
      const response = await api.get('/learningLines');
      return response.data;
    }
  };

  const acceptVersion = async (assignmentId, comment, file) => {
    if (!file) {
      await post(`/assignments/${assignmentId}/acceptLastVersion`, {
        comment: comment,
      });
    } else {
      await sendFormData(
        `/assignments/${assignmentId}/acceptLastVersion`,
        comment,
        file
      );
    }
    refresh();
  };

  const rejectVersion = async (assignmentId, comment, file) => {
    if (!file) {
      await post(`/assignments/${assignmentId}/rejectLastVersion`, {
        comment: comment,
      });
    } else {
      await sendFormData(
        `/assignments/${assignmentId}/rejectLastVersion`,
        comment,
        file
      );
    }
    refresh();
  };
  const requestChangesVersion = async (assignmentId, comment, file) => {
    if (!file) {
      await post(`/assignments/${assignmentId}/requestChangesLastVersion`, {
        comment: comment,
      });
    } else {
      await sendFormData(
        `/assignments/${assignmentId}/requestChangesLastVersion`,
        comment,
        file
      );
    }
    refresh();
  };

  const sendFormData = async (url, comment, file) => {
    const body = new FormData();
    body.append('comment', comment);
    body.append('file', file);
    const response = await post(url, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  };

  const retractVersion = async (assignmentId, comment) => {
    const _response = await post(
      `/assignments/${assignmentId}/retractLastVersion`,
      {
        comment: comment,
      }
    );
    refresh();
    return _response;
  };

  const getCompletionStatus = (tasks) => {
    // const amountToComplete = tasks.filter((t) => t.taskType !== 'read').length;
    const amountToComplete = tasks.length;
    let completedTasks = [];
    tasks.forEach((task) => {
      const assignment = getAssignmentByTaskID(task.taskId);
      if (
        task.taskType === 'read' &&
        ['submitted', 'accepted'].indexOf(assignment?.state) !== -1
      ) {
        completedTasks.push(assignment); // Do not count for Completion?
      }
      if (task.taskType !== 'read' && assignment?.state === 'accepted') {
        completedTasks.push(assignment);
      }
    });

    return [completedTasks.length, amountToComplete];
  };

  const getCompletionStatusText = (tasks) => {
    const completion = getCompletionStatus(tasks);
    return `${completion[0]} / ${completion[1]}`;
  };

  const getCompletionStatusBoolean = (tasks) => {
    const completion = getCompletionStatus(tasks);
    return completion[0] === completion[1];
  };

  const getCompletionStatusPercentage = (tasks) => {
    const status = getCompletionStatus(tasks);
    return Math.round((status[0] / status[1]) * 100);
  };

  const getCurrentTask = () => match?.params?.taskId;

  const getActionStatus = (tasks) => {
    // Return which action are needed, in case of submitted, teacher should have a notification of this one.
    const currentSession = Session.current();
    // Todo: implement role check.
    let actionableArray = [];
    tasks
      // .filter((t) => t.taskType !== 'read')
      .forEach((task) => {
        const assignment = getAssignmentByTaskID(task.taskId);
        if (task.taskType === 'read' && assignment?.state === 'submitted') {
          actionableArray.push('accepted');
        } else {
          actionableArray.push(assignment?.state);
        }
        if (task.taskId === 'b61bea11-f9aa-4c6d-8deb-ab4629066a7e') {
          console.log(assignment);
        }
      });
    if (!isTrainee && actionableArray.includes('submitted')) {
      // Submitted should not be able to co-exist with other states.
      return 'submitted';
    }
    if (
      isTrainee &&
      actionableArray.includes('rejected') &&
      !actionableArray.includes('accepted')
    ) {
      return 'rejected';
    }
    if (
      isTrainee &&
      actionableArray.includes('changes_requested') &&
      !actionableArray.includes('accepted')
    ) {
      return 'changes_requested';
    }
    if (actionableArray.every((s) => s === 'accepted')) {
      return 'accepted';
    }
    return 'empty';
  };
  const refresh = () => queryClient.invalidateQueries(['assignments']);

  const getLearningLine = () => {
    let _learningLineId = learningLineId || match?.params.learningLineId;
    return learningLinesWithCompetencies?.find(
      (l) => l.learningLineId == _learningLineId
    );
  };

  const getTasks = () => {
    // Only map them once.
    if (!tasks) {
      let _tasks = getLearningLine().phases.flatMap((phase) =>
        phase.practices.flatMap((practice) =>
          practice.tasks.map((task) => {
            return {
              ...task,
              practiceId: practice.practiceId,
              phaseId: phase.phaseId,
            };
          })
        )
      );
      setTasks(_tasks);
      return _tasks;
    } else {
      return tasks;
    }
  };

  const getTaskByTaskId = (taskId) => {
    return getTasks().find((task) => task.taskId === taskId);
  };

  const getPracticeByPracticeId = (practiceId) => {
    const phaseindex = getLearningLine().phases.findIndex((p) =>
      p.practices.find((pr) => pr.practiceId === practiceId)
    );
    return getLearningLine().phases[phaseindex].practices.find(
      (pr) => pr.practiceId === practiceId
    );
  };

  const getNextTaskFromPracticeId = (practiceId) => {
    // Returns the first task that needs assignment or review.
    // Skips tasks that are already accepted.
    let _tasks = getTasks().filter((task) => task.practiceId === practiceId);
    const filtered = _tasks.filter((task) => {
      const currentAssignment = getAssignmentByTaskID(task.taskId);
      return !(
        currentAssignment?.state === 'accepted' ||
        (task.taskType === 'read' && currentAssignment?.state === 'submitted')
      );
    });
    return filtered.length > 0 ? filtered[0] : _tasks[0];
  };

  const getNextPractice = (practiceId) => {
    const phaseindex = getLearningLine().phases.findIndex((p) =>
      p.practices.find((pr) => pr.practiceId === practiceId)
    );
    const practiceIndex = getLearningLine().phases[
      phaseindex
    ].practices.findIndex((pr) => pr.practiceId === practiceId);

    return (
      getLearningLine().phases[phaseindex].practices[practiceIndex + 1] ??
      getLearningLine()?.phases[phaseindex + 1]?.practices[0] ??
      null
    );
  };

  const getPracticeModalContent = (practiceId) => {
    let _tasks = getTasks().filter((task) => task.practiceId === practiceId);
    let initialStep = 0;
    const _phaseindex = getLearningLine().phases.findIndex((p) =>
      p.practices.find((pr) => pr.practiceId === practiceId)
    );
    let onOpen;
    const _phase = getLearningLine().phases[_phaseindex];
    const steps = _tasks.map((task, index) => {
      const currentAssignment = getAssignmentByTaskID(task.taskId);
      if (getCurrentTask() === task.taskId) {
        initialStep = index;
      }
      if (!useResourceGroupId) {
        onOpen = () => {
          gotoRoute('development.course-detail', {
            learningLineId: getLearningLine().learningLineId,
            taskId: task.taskId,
          });
        };
      }

      return {
        label: task.name,
        component: (
          <LearninglineTask task={task} assignment={currentAssignment} />
        ),
        status: currentAssignment?.state,
        onOpen: onOpen,
      };
    });
    return {
      steps,
      initialStep,
      phase: _phase,
      nextStep: getNextPractice(practiceId),
      practice: _phase.practices.find((p) => p.practiceId == practiceId),
    };
  };

  const listCompetencies = (learningLines) => {
    const maincompetencies = [];

    learningLines.forEach((learningLine) => {
      if (learningLine?.competencies && learningLine.competencies.length > 0) {
        learningLine?.competencies?.forEach((competencie) => {
          if (!maincompetencies.find((c) => c.id == competencie.id)) {
            maincompetencies.push({
              ...competencie,
              learningLineId: competencie.id,
              phases: [],
            });
          }
        });
        learningLine.phases.forEach((phase) => {
          phase.practices.forEach((practice) => {
            practice.tasks.forEach((task) => {
              if (task.competencies.length > 0) {
                task.competencies.forEach((competency) => {
                  const line = maincompetencies.findIndex(
                    (l) => l.id === competency.parent
                  );
                  // Check if phase is already made
                  let phaseIndex = maincompetencies[line].phases.findIndex(
                    (p) => p.id === competency.id
                  );
                  if (phaseIndex === -1) {
                    maincompetencies[line].phases.push({
                      ...competency,
                      practices: [],
                      phaseId: maincompetencies[line].phases.length,
                    });
                    phaseIndex = maincompetencies[line].phases.length - 1;
                  }
                  let practiceIndex = maincompetencies[line].phases[
                    phaseIndex
                  ].practices.findIndex(
                    (p) =>
                      p.practiceId ===
                      `${learningLine.learningLineId}__${maincompetencies[line].phases[phaseIndex].phaseId}`
                  );
                  if (practiceIndex === -1) {
                    maincompetencies[line].phases[phaseIndex].practices.push({
                      practiceId: `${learningLine.learningLineId}__${maincompetencies[line].phases[phaseIndex].phaseId}`,
                      name: learningLine.name,
                      tasks: [],
                    });
                    practiceIndex =
                      maincompetencies[line].phases[phaseIndex].practices
                        .length - 1;
                  }

                  maincompetencies[line].phases[phaseIndex].practices[
                    practiceIndex
                  ].tasks.push(task);
                });
              }
            });
          });
        });
      }
    });

    return maincompetencies;
  };

  const learningLinesWithCompetencies = showCompetencies
    ? [
        ...learningLines.filter((l) => l.learningLineId !== 'custom'),
        ...listCompetencies(learningLines),
      ]
    : learningLines.filter((l) => l.learningLineId !== 'custom');

  const toggleCompetencies = () => {
    setShowCompetencies((o) => !o);
  };

  const value = {
    assignments,
    assignmentFiles,
    addVersion,
    getAssignmentByTaskID,
    fetchAssignmentFileList,
    acceptVersion,
    rejectVersion,
    requestChangesVersion,
    retractVersion,
    getCompletionStatus,
    getCompletionStatusText,
    getCompletionStatusBoolean,
    getCompletionStatusPercentage,
    getActionStatus,
    isSubmitting,
    isFetching,
    refresh,
    getLearningLine,
    getTasks,
    getTaskByTaskId,
    getPracticeByPracticeId,
    getCurrentTask,
    getPracticeModalContent,
    getNextTaskFromPracticeId,
    learningLines: learningLinesWithCompetencies,
    learningLinesWithCompetencies,
    showCompetencies,
    toggleCompetencies,
    isTrainee,
    isTeacher,
    setIsTeacher,
    setIsTrainee,
    setLearningLineId,
    useResourceGroupId,
  };

  return (
    <AssignmentContext.Provider value={value}>
      {children}
    </AssignmentContext.Provider>
  );
};
