import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { HockeyMatrixVideoFragmentGenerator } from '../../../lib/VideoFragment/Generator';
import { VideoFragmentCollector } from '../../../lib/VideoFragment';
import videoCollection from '../../../domain/Video';
import { VideoPlayer } from '../../../lib/Observation/ObservationLogVideoPlayer';
import { Modal } from '../../../lib';
import VideoAngleContextProvider from '../../../lib/VideoAngleContextProvider';

export const Matrix = ({
  sportingEvent,
  homeTeamReport,
  awayTeamReport,
  homeTeam,
  awayTeam,
  reportState,
  onClick,
}) => {
  const [observations, setObservations] = useState(null);
  const [playlist, setPlaylist] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState(null);

  useEffect(() => {
    (async () => {
      const observations = await sportingEvent.observationLog.fetchIfEmpty();
      await videoCollection.fetchIfEmpty(sportingEvent.mainVideoId());
      setObservations(sportingEvent.observationLog);
    })();
  }, [sportingEvent]);

  let video = videoCollection.get(sportingEvent.mainVideoId());

  const createPlaylist = (label, code) => {
    const videoFragmentCollector = new VideoFragmentCollector();
    videoFragmentCollector.addGenerator(
      new HockeyMatrixVideoFragmentGenerator()
    );
    let _observations = observations.toArray().filter((item) => {
      let _item = item.toJS();
      return (
        _item?.attributes?.code === code &&
        _item?.attributes?.labels.find((l) => l.text === label)
      );
    });

    const videoFragmentCollection = videoFragmentCollector.collect(
      video,
      sportingEvent,
      _observations
    );

    setPlaylist(videoFragmentCollection.getVideoFragments('unknown', code));
    setSelectedLabel(`${code}:${label}`);
  };

  if (observations) {
    return (
      <div>
        <MatrixPlayer
          key={'matrixplayer'}
          playlist={playlist}
          selectedLabel={selectedLabel}
          onClose={() => {
            setPlaylist(null);
            setSelectedLabel(null);
          }}
          video={video}
        />
        <DataMatrix
          key={'matrix'}
          createPlaylist={createPlaylist}
          observations={observations}
        />
      </div>
    );
  }

  return null;
};

const DataMatrix = ({ observations, createPlaylist }) => {
  const data = useMemo(
    () =>
      observations
        .toArray()
        .filter((d) => d.has('attributes'))
        .map((o) => o.get('attributes')),
    [observations]
  );

  const uniqueLabels = useMemo(
    () =>
      [
        ...new Set(
          data.flatMap((item) => item.labels?.map((label) => label.text) || [])
        ),
      ].sort(),
    [data]
  );

  // Extract and sort unique codes (Y-axis)
  const uniqueCodes = useMemo(
    () => [...new Set(data.map((item) => item.code))].sort(),
    [data]
  );

  // Precompute counts for faster lookup
  const countMap = useMemo(() => {
    const map = new Map();
    data.forEach((item) => {
      if (item.code && item.labels) {
        item.labels.forEach((label) => {
          const key = `${item.code}-${label.text}`;
          map.set(key, (map.get(key) || 0) + 1);
        });
      }
    });
    return map;
  }, [data]);

  const handleClick = useCallback(
    (label, code) => {
      createPlaylist(label, code);
    },
    [createPlaylist]
  );

  return (
    <div className="matrix-table">
      <table className="min-w-full">
        <thead>
          <tr className="bg-gray-200">
            <th className="p-2"></th>
            {uniqueLabels.map((label, index) => (
              <th key={index} className="p-2">
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {uniqueCodes.map((code, rowIndex) => (
            <tr key={rowIndex}>
              <td className="p-1 font-bold">{code}</td>
              {uniqueLabels.map((label, colIndex) => {
                const count = countMap.get(`${code}-${label}`) || '';
                return (
                  <td
                    key={colIndex}
                    onClick={() => handleClick(label, code)}
                    className={`p-1 text-center ${
                      count === '' ? 'empty' : count > 10 ? 'high' : 'low'
                    }`}
                  >
                    {count}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const MatrixPlayer = ({ playlist, video, selectedLabel, onClose }) => {
  const [activeItem, setActiveItem] = useState(0);
  const Pagination = () => {
    return (
      <div>
        {playlist?.map((i, index) => {
          return (
            <div
              className={`
                  observation-circle float-left text-center clickable ${
                    activeItem === index ? 'active' : ''
                  } `}
              onClick={() => setActiveItem(index)}
            >
              {index + 1}
            </div>
          );
        })}
      </div>
    );
  };

  if (!playlist) {
    return null;
  }
  return (
    <>
      <Modal
        size={'md'}
        key={'modal'}
        onCloseClick={() => {
          onClose();
          setActiveItem(0);
        }}
      >
        <div className="modal-header">
          <h5>{selectedLabel}</h5>
        </div>
        <div className={'matrix-player'}>
          <VideoAngleContextProvider>
            <VideoPlayer
              videoFragments={playlist}
              video={video}
              onPlaylistItem={setActiveItem}
              title={selectedLabel}
              buttons={video.privilegedTo('download') ? ['download'] : []}
              activePlayListItem={activeItem}
              multiCam={false}
            />
          </VideoAngleContextProvider>
        </div>
        <Pagination />
      </Modal>
    </>
  );
};
