import React from 'react';
import { useAssignmentContext } from './assignmentContext';
import { MultiStepModal } from '../../../lib/MultiStepModal';
import { gotoRoute } from '../../route';

export const TaskModal = ({
  currentTaskId,
  currentPracticeId,
  onCloseClick,
}) => {
  const assignmentContext = useAssignmentContext();
  let _currentTask = currentTaskId || assignmentContext.getCurrentTask();
  if (
    currentTaskId ||
    assignmentContext.getCurrentTask() ||
    currentPracticeId
  ) {
    let modalContent;
    if (currentPracticeId) {
      modalContent =
        assignmentContext.getPracticeModalContent(currentPracticeId);
    } else {
      const _task = assignmentContext.getTaskByTaskId(_currentTask);
      modalContent = assignmentContext.getPracticeModalContent(
        _task?.practiceId
      );
    }

    if (modalContent?.steps.length > 0) {
      return (
        <MultiStepModal
          modalProps={null}
          isCompleteable={true}
          steps={modalContent.steps}
          initialStep={Math.min(
            modalContent.initialStep,
            modalContent.steps.length - 1
          )}
          onClose={() =>
            onCloseClick
              ? onCloseClick()
              : gotoRoute('development.course-detail', {
                  learningLineId:
                    assignmentContext.getLearningLine().learningLineId,
                })
          }
          onComplete={() =>
            onCloseClick
              ? onCloseClick()
              : gotoRoute('development.course-detail', {
                  learningLineId:
                    assignmentContext.getLearningLine().learningLineId,
                })
          }
        />
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};
