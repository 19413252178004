import React from 'react';
import {
  AbsoluteFill,
  interpolate,
  spring,
  useCurrentFrame,
  useVideoConfig,
} from 'remotion';

import placeHolder from './logos/club_placeholder.svg';
import { loadFont } from '@remotion/google-fonts/Lato';

export const ClubLogoIntro: React.FC = ({ clip }) => {
  const frame = useCurrentFrame();
  const { fontFamily } = loadFont();
  const { fps, durationInFrames, width, height } = useVideoConfig();

  // Fade-in for the first second (adjust if needed)
  const fadeInOpacity = 1;
  //     interpolate(
  //   frame,
  //   [0, 0.2 * durationInFrames],
  //   [0, 1],
  //   {
  //     extrapolateRight: 'clamp',
  //   }
  // );

  // Small fade-out in the last 20 frames
  const fadeOutOpacity = interpolate(
    frame,
    [durationInFrames - 0.5 * durationInFrames, 0.7 * durationInFrames],
    [1, 0],
    { extrapolateLeft: 'clamp', extrapolateRight: 'clamp' }
  );
  const fadeOutPosition = interpolate(
    frame,
    [durationInFrames - 0.5 * durationInFrames, 0.7 * durationInFrames],
    [0, 1],
    { extrapolateLeft: 'clamp', extrapolateRight: 'clamp' }
  );

  const introStyles = {
    h3: {
      fontFamily: [fontFamily],
      color: '#ffffff',
      margin: '0 0 0.5em 0',
      lineHeight: 1.1,
      textAlign: 'center',
      textShadow: '1em 1em 3em rgba(0,0,0,0.75)',
      fontSize: '2.5em',
    },
    h4: {
      fontFamily: [fontFamily],
      color: '#ffffff',
      margin: '0 0 0.5em 0',
      lineHeight: 1.1,
      textAlign: 'center',
      textShadow: '1em 1em 3em rgba(0,0,0,0.75)',
      fontSize: '2em',
      fontWeight: 'normal',
    },
    bgImage: {
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      filter: 'grayscale(75%) brightness(0.5) blur(4px)',
      boxShadow: 'inset 0 0 200px rgba(0,0,0,1)',
    },
  };

  // Combine fadeIn and fadeOut
  const opacity = fadeInOpacity * fadeOutOpacity;
  let videoThumb = clip?.options?.thumbnailUrl;
  return (
    <AbsoluteFill
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        opacity: 1,
        zIndex: 999,
      }}
    >
      <div
        className="bg-image"
        style={{
          ...introStyles.bgImage,
          // backgroundImage: `url(${videoThumb})`,
          opacity: 1,
        }}
      ></div>
      <AbsoluteFill>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          {/* Top-left triangle (white) */}
          <div
            style={{
              position: 'absolute',
              top: `${-100 * fadeOutPosition}%`, // animate movement
              left: 0,
              width: 0,
              height: 0,
              borderStyle: 'solid',
              borderWidth: `${height}px ${width}px 0px 0px`,
              borderColor: `${
                clip?.options?.color2 ?? 'var(--colorSecondary)'
              } transparent transparent transparent`,
            }}
          />

          {/* Bottom-right triangle (orange) */}
          <div
            style={{
              position: 'absolute',
              top: `${100 * fadeOutPosition}%`, // animate opposite
              right: 0,
              width: 0,
              height: 0,
              borderStyle: 'solid',
              borderWidth: `0 0 ${height}px ${width}px`,
              borderColor: `transparent transparent ${
                clip?.options?.color1 ?? 'var(--colorPrimary)'
              } transparent`,
            }}
          />
        </div>
      </AbsoluteFill>
      <div
        style={{
          width: '100%',
          maxWidth: '35%',
          maxHeight: '35%',
          transform: `scale(${1 + 1 - fadeOutOpacity})`,
          paddingBottom: '',
          opacity,
        }}
      >
        <img
          style={{ objectFit: 'contain', width: '100%', height: '100%' }}
          src={clip?.options?.logo[0] ?? placeHolder}
        />
      </div>

      <div
        className="titles"
        style={{
          position: 'absolute',
          width: '75%',
          margin: '0 auto',
          left: '12.5%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center',
          alignContent: 'center',
          paddingBottom: '2%',
          opacity: opacity,
          // bottom: '5%',
        }}
      >
        <h3 style={introStyles.h3}>{clip?.options?.title ?? 'Highlights'}</h3>
        <h4 style={introStyles.h4}>
          {clip?.options?.subtitle ?? '22-12-2025'}
        </h4>
      </div>
    </AbsoluteFill>
  );
};
