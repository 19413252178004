import React from 'react';

import { Default } from './intros/default/Default';
import DefaultConfig from './intros/default/info.json';

import {
  EhtvIntro,
  EhtvOutro,
  introConfig as ehtvIntroConfig,
  outroConfig as ehtvOutroConfig,
} from './intros/ehtv/Ehtv';

import {
  OranjeRoodIntro,
  OranjeRoodOutro,
  introConfig as oranjeRoodIntroConfig,
  outroConfig as oranjeRoodOutroConfig,
} from './intros/oranjerood/OranjeRood';

import {
  SouthgateIntro,
  SouthgateOutro,
  introConfig as southgateIntroConfig,
  outroConfig as southgateOutroConfig,
} from './intros/southgate/Southgate';

import { SponsorStill } from './stills/sponsorStill/SponsorStill';
import SponsorConfig from './stills/sponsorStill/config.json';
import { Scoreboard } from './overlays/scoreboard';
import { TriangleWipe } from './transitions/triangleWipe';
import TriangleWipeConfig from './transitions/triangleWipe/config.json';
import { HighlightClip } from './Highlights/Clip';
import { TeamtvOutro } from './intros/ttv-outro/TeamtvOutro';
import TeamTVOutroConfig from './intros/ttv-outro/config.json';
import { Premount } from './Highlights/Premount';
import { useCurrentFrame } from 'remotion';
import { PlaceHolder } from './stills/PlaceHolder';
import { ClubLogoIntro } from './intros/clubLogo/ClubLogoIntro';
import ClubLogoConfig from './intros/clubLogo/info.json';

const components = {
  // Component, config.json
  'intro.Default': [Default, DefaultConfig],
  'intro.club': [ClubLogoIntro, ClubLogoConfig],

  'intro.Ehtv': [EhtvIntro, ehtvIntroConfig],
  'outro.Ehtv': [EhtvOutro, ehtvOutroConfig],

  'intro.Southgate': [SouthgateIntro, southgateIntroConfig],
  'outro.Southgate': [SouthgateOutro, southgateOutroConfig],

  'intro.OranjeRood': [OranjeRoodIntro, oranjeRoodIntroConfig],
  'outro.OranjeRood': [OranjeRoodOutro, oranjeRoodOutroConfig],

  'still.SponsorStill': [SponsorStill, SponsorConfig],
  'overlays.Scoreboard': [Scoreboard, {}],
  'transition.TriangleWipe': [TriangleWipe, TriangleWipeConfig],
  'clip.Video': [HighlightClip, {}],
  'outro.Default': [TeamtvOutro, TeamTVOutroConfig],
  'clip.placeholder': [PlaceHolder, { duration: 1 }],
};

export const RenderClipComponent = ({ type, premountTime, ...props }) => {
  const frame = useCurrentFrame();
  const Component = components[type][0];
  if (Component) {
    if (premountTime) {
      return (
        <Premount for={premountTime}>
          <div style={{ opacity: frame < premountTime ? 0 : 1 }}>
            <Component {...props} />
          </div>
        </Premount>
      );
    } else {
      return <Component {...props} />;
    }
  } else {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          color: 'red',
          width: '100%',
          height: '100%',
          fontSize: 32,
        }}
      >
        Component {type} not found...
      </div>
    );
  }
};
export const getClipConfig = (type) => {
  if (components[type]?.length) {
    return components[type][1];
  }
};
