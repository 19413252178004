import {
  AbsoluteFill,
  Audio,
  Easing,
  interpolate,
  Sequence,
  useCurrentFrame,
  useVideoConfig,
  getRemotionEnvironment,
} from 'remotion';
import React, { Fragment } from 'react';
import {
  linearTiming,
  springTiming,
  TransitionSeries,
} from '@remotion/transitions';

import { RenderClipComponent } from '../clipRegistry';
import { Watermark } from '../overlays/watermarks/Watermark';
import { Sponsors } from '../overlays/Sponsors/sponsors';
import { BackgroundMusic } from './BackgroundMusic';
import { wipe } from '@remotion/transitions/wipe';
import { fade } from '@remotion/transitions/fade';
import { clockWipe } from '@remotion/transitions/clock-wipe';
import { ReplayPresentation } from '../transitions/ReplayTransition';
import { ReplayOverlay } from '../overlays/Replay';
import { TriangleWipePresentation } from '../transitions/triangleWipe';
import zIndexes from '../helpers/zIndexes.json';

// premount in seconds
// DONT USE THIS. IT'S BROKEN!!!
const PREMOUNT_DEFAULT = 0;
//
// const sponsors = [
//   {
//     img: 'https://d2n39vzhondf7p.cloudfront.net/static/styles/medium/s3/media-image/61af260a5791018f57ec3a3b_teamtv-knkv-min.png?itok=Lt3sclSN',
//     screenTime: 0,
//   },
//   {
//     img: 'https://d2n39vzhondf7p.cloudfront.net/static/styles/medium/s3/media-image/61e679f5caf5118e47d08c88_teamtv-oranjerood-hockey.png?itok=4XuvlShg',
//     screenTime: 0,
//   },
//   {
//     img: 'https://d2n39vzhondf7p.cloudfront.net/static/styles/medium/s3/media-image/61dffa943b1f7ddd0ceedd66_teamtv-braxgata-hockey.png?itok=jGGmrLcz',
//     screenTime: 0,
//   },
// ];

export const HighlightsComposition: React.FC<{
  content: any[];
  fps: number;
  totalDurationInFrames: number;
  template: any;
  audioTrackUrl?: string;
  sponsorTimeBlocks: any[];
  sponsors: any[];
  config: any[];
}> = ({
  content,
  fps,
  template,
  audioTrackUrl,
  totalDurationInFrames,
  sponsorTimeBlocks,
  sponsors,
  config,
}) => {
  const videoConfig = useVideoConfig();
  const { isRendering } = getRemotionEnvironment();

  const userContentStart =
    Math.max(
      ...content
        .filter(
          (item) => item.type.startsWith('intro.') && item.type !== 'intro.club'
        )
        .map((item) => item.endTime + item.offset.end - 0.5),
      0
    ) * fps;

  const outroStartTimes = content
    .filter((item) => item.type.startsWith('outro.'))
    .map((item) => item.startTime - item.offset.start);

  const userContentEnd =
    (outroStartTimes.length > 0
      ? Math.min(...outroStartTimes)
      : totalDurationInFrames) * fps;

  const absoluteFrame = useCurrentFrame();
  const backgroundMusicProps = {
    startAt: userContentStart,
    durationInFrames: userContentEnd - userContentStart,
    fps,
    backgroundMusic: config?.backgroundMusic?.trackId,
    volume: parseFloat(config?.backgroundMusic?.volume) ?? 0.4,
  };

  const sponsorIds = config?.sponsors?.sponsorIds || [];
  const sponsorDuration = config?.sponsors?.duration || 8;
  const highlightVideoSponsors = sponsors
    .filter((sponsor) => sponsorIds.indexOf(sponsor.sponsorId) !== -1)
    .map((sponsor) => ({ imageUrl: sponsor.imageUrl, screenTime: 0 }));

  return (
    <AbsoluteFill
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
        fontSize: Math.floor(
          Math.min(videoConfig.height, videoConfig.width) / 50
        ),
        '--colorPrimary': config?.colors?.colorPrimary ?? '#52da95',
        '--colorSecondary': config?.colors?.colorSecondary ?? '#8452da',
      }}
    >
      <TransitionSeries>
        {content.map((contentItem, index) => {
          let offsetStart = contentItem?.offset?.start || 0;
          let offsetEnd = contentItem?.offset?.end || 0;
          let durationInFrames =
            (contentItem.endTime -
              contentItem.startTime +
              offsetStart +
              offsetEnd) *
            fps;
          let startAt = (contentItem.startTime - offsetStart) * fps;

          let premountTime = isRendering ? 0 : PREMOUNT_DEFAULT * fps;
          // 3 sec premount time to sneakily not show controls on ios.
          if (startAt - premountTime > 0) {
            startAt -= premountTime;
          } else {
            // set to max possible premount time (time of intro probably)
            premountTime = premountTime - startAt;
            startAt = 0;
          }
          let transitionPresentation;
          let transitionPresentationConfig;
          let transitionTiming = springTiming({
            durationInFrames: 50,
            config: { damping: 200 },
          });
          if (content[index + 1] && content[index + 1]?.transition) {
            switch (content[index + 1]?.transition) {
              case 'fadeOut':
              case 'fadeIn':
                transitionPresentation = fade;
                transitionTiming = linearTiming({ durationInFrames: 50 });
                break;
              case 'wipe':
                transitionPresentation = wipe;
                break;
              case 'clockWipe':
                transitionPresentation = clockWipe;
                transitionTiming = linearTiming({ durationInFrames: 50 });
                transitionPresentationConfig = {
                  width: videoConfig.width,
                  height: videoConfig.height,
                  outerEnterStyle: {},
                  outerExitStyle: {},
                  innerEnterStyle: { zIndex: 0 },
                  innerExitStyle: { zIndex: 0 },
                };
                break;
              case 'triangleWipe':
                transitionPresentation = TriangleWipePresentation;
                // transitionTiming = linearTiming({ durationInFrames: 50 });
                transitionPresentationConfig = {
                  colorPrimary: config?.colors?.colorPrimary ?? '#52da95',
                  colorSecondary: config?.colors?.colorSecondary ?? '#8452da',
                };
                break;
              case 'replay':
                transitionPresentation = ReplayPresentation;
                transitionPresentationConfig = {
                  color: config?.colors?.colorPrimary ?? '#52da95',
                };
                transitionTiming = linearTiming({
                  durationInFrames: 3.2 * fps,
                });
                break;
            }
          }
          let key = `seq-${contentItem.contentId}-${startAt}-${index}`;

          return (
            <Fragment key={key}>
              <TransitionSeries.Sequence
                key={`${key}-sequence`}
                durationInFrames={durationInFrames}
              >
                <RenderClipComponent
                  type={contentItem.type}
                  durationInFrames={durationInFrames}
                  clip={contentItem}
                  premountTime={premountTime}
                />
                {template?.watermark && contentItem.type === 'clip.Video' && (
                  <AbsoluteFill
                    style={{
                      zIndex: zIndexes.watermark,
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        width: '14em',
                        opacity: 0.7,
                        bottom: '4.2em',
                        right: '2.2em',
                      }}
                    >
                      <Watermark watermark={template.watermark} />
                    </div>
                  </AbsoluteFill>
                )}
                {contentItem?.effects?.length > 0 &&
                  contentItem?.effects[0] == 'replay' && (
                    <ReplayOverlay
                      clip={contentItem}
                      nextClip={content[index + 1]}
                      durationInFrames={durationInFrames}
                    />
                  )}
              </TransitionSeries.Sequence>
              {transitionPresentation && (
                <TransitionSeries.Transition
                  key={`${key}-sequence`}
                  timing={transitionTiming}
                  presentation={transitionPresentation({
                    ...transitionPresentationConfig,
                  })}
                />
              )}
            </Fragment>
          );
        })}
      </TransitionSeries>

      {audioTrackUrl && (
        <Audio
          volume={1}
          src={audioTrackUrl}
          from={0}
          to={totalDurationInFrames}
        />
      )}
      <AbsoluteFill
        style={{
          zIndex: zIndexes.sponsors,
        }}
      >
        <Sponsors
          sponsors={highlightVideoSponsors}
          sponsorTimeBlocks={sponsorTimeBlocks}
          absoluteFrame={absoluteFrame}
          sponsorDuration={sponsorDuration}
        />
      </AbsoluteFill>
      <BackgroundMusic
        startAt={userContentStart}
        durationInFrames={userContentEnd - userContentStart}
        fps={fps}
        backgroundMusic={backgroundMusicProps.backgroundMusic}
        volume={backgroundMusicProps.volume}
      />
    </AbsoluteFill>
  );
};

const HighLightSequence = ({ children, from, durationInFrames, name }) => {
  return (
    <Sequence from={from} durationInFrames={durationInFrames} name={name}>
      {children}
    </Sequence>
  );
};
