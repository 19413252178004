import React, { useEffect, useRef, useState } from 'react';
import SportingEventCollection from '../../../../domain/SportingEvent';
import VideoCollection from '../../../../domain/Video';
import { gotoRoute } from '../../../route';
import { Session } from '../../../../domain/Session';
import moment from 'moment';
import { PastMatchRow } from './PastMatchRow';
import { FutureMatchRow } from './FutureMatchRow';
import UploadVideo from '../UploadVideo';
import { ImportObservations } from '../ImportObservations';
import { DefaultExporter, KorfballExport } from '../../../../infra/Export';
import TeamCollection from '../../../../domain/Team';
import sweetAlert from '../../../../lib/sweetAlert';
import { useTranslation } from 'react-i18next';
import { Command, CommandBus } from '../../../../infra/Messaging';
import { EditMatchInput, ShareTagsModalContent } from '../EditMatch';
import { confirmModal } from '../../../../lib/Confirmation';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Integrations } from '../Integrations/Integrations';

const getOrBuildSportingEvent = (sportingEvent) => {
  let localCopy = checkLocalCopy(sportingEvent);
  let _se;
  if (localCopy !== null) {
    _se = localCopy;
  } else if (SportingEventCollection.get(sportingEvent.sportingEventId)) {
    _se = SportingEventCollection.get(sportingEvent.sportingEventId);
  } else {
    _se = SportingEventCollection.build(sportingEvent);
  }
  return _se;
};

export const SportingEventComponent = ({
  sportingEvent,
  refetch = () => {}, // Tell parent component this event has been invalidated eg. for invalidateQuery
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const { t } = useTranslation('module.match');

  const currentSession = Session.current();

  sportingEvent = getOrBuildSportingEvent(sportingEvent);

  let unsetModalComponent; // first define it, so we can use it, after useState define the actual implementation.
  const [modalComponent, setModalComponent] = useState(
    getInitialModalComponent(sportingEvent, () => unsetModalComponent())
  );
  unsetModalComponent = () => {
    setModalComponent(null);
    refetch();
  };

  const isArchived = sportingEvent.isArchived();

  useEffect(() => {
    (async () => {
      await Promise.all([getTeams(sportingEvent), getVideos(sportingEvent)]);
      await currentSession.isReady();
      setIsLoaded(true);
    })();
  }, []);

  if (!isLoaded || !sportingEvent) {
    return (
      <div
        className={'match-overview-item'}
        data-id={sportingEvent?.sportingEventId ?? sportingEvent?.id}
      >
        <Skeleton
          duration={0.9}
          style={{
            height: '57px',
            width: '100%',
            marginBottom: '18px',
            borderRadius: '7px',
          }}
        />
      </div>
    );
  }

  const sportingEventProps = isArchived
    ? {}
    : {
        onUploadVideo: (_sportingEvent) => {
          // e2e uploadVideo.spec.ts
          setModalComponent(
            uploadVideoModal(_sportingEvent, unsetModalComponent)
          );
        },
        onEditSportingEvent: () => {
          // e2e sportingEvent.spec.ts
          setModalComponent(
            editSportingEvent(
              sportingEvent,
              currentSession.sportType(),
              unsetModalComponent
            )
          );
        },
        onEditShareSportingEvent: () => {
          setModalComponent(
            editShareSportingEvent(
              sportingEvent,
              currentSession.sportType(),
              unsetModalComponent
            )
          );
        },
        onImportObservations: () => {
          // e2e sportingEvent.spec.ts
          setModalComponent(
            importObservations(sportingEvent, unsetModalComponent)
          );
        },
        onExportObservations: () => {
          exportObservations(sportingEvent, currentSession.sportType());
        },
        onOpenReport:
          sportingEvent.type === 'match'
            ? (sportingEventId) => {
                gotoRoute('reporting.detail', { sportingEventId });
              }
            : null,
        onLivestreamOverlay:
          sportingEvent.type === 'match'
            ? (sportingEventId) => {
                gotoRoute('match.livestream', { sportingEventId });
              }
            : null,
        onManageLineUp:
          sportingEvent.type === 'match'
            ? (sportingEventId) => {
                gotoRoute('lineup.detail', { sportingEventId });
              }
            : null,
        onDeleteVideo: () => {
          // e2e sportingEvent.spec.ts
          sweetAlert({
            title: t('deleteVideo'),
            text: t('deleteVideoConfirmMessage'),
            dangerMode: true,
            buttons: [t('common:cancel'), t('deleteVideo')],
          }).then(async (willDelete) => {
            if (willDelete) {
              setIsLoaded(false);
              await deleteMainVideo(sportingEvent).then(() => {
                refetch();
              });
              setIsLoaded(true);
            }
          });
        },
        onDeleteSportingEvent: () => {
          // e2e sportingEvent.spec.ts
          sweetAlert({
            title: t('deleteEvent', { type: t(`type.${sportingEvent.type}`) }),
            text: t('deleteMatchConfirmMessage', {
              name: sportingEvent?.label,
            }),
            dangerMode: true,
            buttons: [
              t('common:cancel'),
              t('deleteEvent', { type: t(`type.${sportingEvent.type}`) }),
            ],
          }).then(async (willDelete) => {
            if (willDelete) {
              setIsLoaded('false');
              await deleteMainVideo(sportingEvent).then(async () => {
                await sportingEvent.destroy();
                refetch();
              });
            }
          });
        },
        onDeleteAnalysis: () => {
          // e2e sportingEvent.spec.ts
          sweetAlert({
            title: t('deleteAnalysis'),
            text: t('deleteAnalysisConfirmMessage'),
            dangerMode: true,
            buttons: [t('common:cancel'), t('deleteAnalysis')],
          }).then(async (willDelete) => {
            if (willDelete) {
              if (!!sportingEvent.tags.copyOf) {
                sportingEvent.destroy();
              } else {
                throw 'SportingEvent is not an analyse';
              }
              refetch();
            }
          });
        },
        onRemoveVideoSynchronizationPoints: () => {
          // e2e sportingEvent.spec.ts
          sweetAlert({
            title: t('removeVideoSynchronizationPoints'),
            text: t('removeVideoSynchronizationPointsConfirmMessage'),
            dangerMode: true,
            buttons: [
              t('common:cancel'),
              t('removeVideoSynchronizationPoints'),
            ],
          }).then((willDelete) => {
            if (willDelete) {
              removeVideoSynchronizationPoints(sportingEvent);
            }
          });
        },
        onOpenDetail: () => {
          gotoRoute('match.detail', { sportingEventId: sportingEvent.id });
        },
        onOpenPlay: () => {
          // e2e sportingEvent.spec.ts
          gotoRoute(`play.${sportingEvent.type ?? 'match'}`, {
            sportingEventId: sportingEvent.id,
          });
        },
        onOpenSetACL: () => {
          // e2e sportingEvent.spec.ts
          console.log('onOpenSetACL');
          onOpenSetACL(sportingEvent, t);
        },
        onOpenIntegrations: () => {
          setModalComponent(
            <Integrations
              onClose={() => setModalComponent(null)}
              sportingEvent={sportingEvent}
            />
          );
        },
      };

  if (getPropablyEnded(sportingEvent)) {
    return (
      <>
        {modalComponent}
        <PastMatchRow
          {...sportingEventProps}
          sportingEvent={sportingEvent}
          locked={isArchived}
        />
      </>
    );
  } else {
    return (
      <>
        {modalComponent}
        <FutureMatchRow
          {...sportingEventProps}
          sportingEvent={sportingEvent}
          locked={isArchived}
        />
      </>
    );
  }
};

/*
 *   Sporting Event Functions.
 * */

const checkLocalCopy = (sportingEvent) => {
  let localCopy = null;
  for (const se of SportingEventCollection.toArray()) {
    if (se.tags.copyOf === sportingEvent.sportingEventId) {
      localCopy = se;
      break;
    }
  }
  return localCopy;
};

const getTiming = (scheduledAt) => {
  const today = moment();
  const eventMoment = moment(scheduledAt);

  if (eventMoment.isSame(today, 'day')) {
    return 'Today';
  } else if (eventMoment.isAfter(today, 'day')) {
    return 'Future';
  } else {
    return 'Past';
  }
};

const uploadVideoModal = (sportingEvent, onClose) => {
  return <UploadVideo sportingEvent={sportingEvent} onReady={onClose} />;
};

const importObservations = (sportingEvent, onClose) => {
  return <ImportObservations sportingEvent={sportingEvent} onReady={onClose} />;
};

const editSportingEvent = (sportingEvent, sportType, onClose) => {
  // Todo: Invalidate sportingEvent after modifications.
  return (
    <EditMatchInput
      sportingEvent={sportingEvent}
      onReady={() => onClose()}
      sportType={sportType}
    />
  );
};

const editShareSportingEvent = (sportingEvent, sportType, onClose) => {
  return (
    <ShareTagsModalContent
      sportingEvent={sportingEvent}
      onReady={() => onClose()}
      sportType={sportType}
    />
  );
};

const exportObservations = (sportingEvent, sportType) => {
  switch (sportType) {
    case 'korfball':
      KorfballExport.download(sportingEvent);
      break;
    default:
      DefaultExporter.download(sportingEvent);
      break;
  }
};

const getVideos = async (sportingEvent) => {
  /* Preferably higher component will have fetched this already. This is here in case it has not been fetched.*/
  for (const videoId of sportingEvent.videoIds) {
    if (videoId !== 'live-recording' && videoId !== 'filtered') {
      try {
        await VideoCollection.getOrFetch(videoId);
      } catch (e) {
        return false;
      }
    } else {
      return;
    }
  }
};

const getTeams = async (sportingEvent) => {
  const teamPromises = [];

  if (sportingEvent.homeTeamId && sportingEvent.homeTeamId !== 'not_set') {
    teamPromises.push(
      TeamCollection.getOrFetch(sportingEvent.homeTeamId).catch((e) =>
        console.error('Failed to fetch home team:', sportingEvent.homeTeamId, e)
      )
    );
  }

  if (sportingEvent.awayTeamId && sportingEvent.awayTeamId !== 'not_set') {
    teamPromises.push(
      TeamCollection.getOrFetch(sportingEvent.awayTeamId).catch((e) =>
        console.error('Failed to fetch away team:', sportingEvent.awayTeamId, e)
      )
    );
  }

  await Promise.all(teamPromises);
};

const deleteMainVideo = async (sportingEvent, refreshSportingEvent = true) => {
  if (sportingEvent.hasVideo()) {
    const mainVideoId = sportingEvent.mainVideoId();
    if (!!VideoCollection.get(mainVideoId)) {
      await sportingEvent.deleteVideo(mainVideoId, refreshSportingEvent);
      await VideoCollection.fetch();
    }
  }
};

const removeVideoSynchronizationPoints = async (sportingEvent) => {
  const synchronizationPoints = sportingEvent.getVideoSynchronizationPoints();

  const videoId = sportingEvent.mainVideoId();
  if (videoId === null) {
    return;
  }

  const videoClock = sportingEvent.clocks()[videoId];

  const commands = synchronizationPoints.map((synchronizationPoint) =>
    Command.create('SportingEvent.RemoveSynchronizationPoint', {
      sportingEventId: sportingEvent.id,
      clockId: videoClock.clockId,
      type: synchronizationPoint.type,
      key: synchronizationPoint.key,
    })
  );
  CommandBus.dispatch(commands);
};

const onOpenSetACL = async (sportingEvent, t) => {
  if (sportingEvent.isShared()) {
    // Ugly way to force-get a local copy
    sportingEvent = await sportingEvent.getPrivilegedToSportingEventId(
      'destroy'
    );
  }

  const currentSession = Session.current();
  let roles = currentSession.currentResourceGroup().roles;
  await roles.fetchIfEmpty();
  roles = roles.toArray(); // ugly yes
  const allowedRoleNames =
    sportingEvent.allowedRoleNames || roles.map((role) => role.roleName);
  const availableRoleNames = roles.map((role) => role.roleName);
  const toggle = (roleName) => {
    const idx = allowedRoleNames.indexOf(roleName);
    if (idx === -1) {
      allowedRoleNames.push(roleName);
    } else {
      allowedRoleNames.splice(idx, 1);
    }
  };
  const save = () => {
    sportingEvent.setACL(allowedRoleNames);
  };

  const toggleableRolesPerRole = {
    trainer: ['peer', 'player'], // TODO: this should move to backend Policy.
    trainee: ['trainer', 'peer'],
    admin: ['trainer', 'trainee', 'peer'],
    peer: [], // Peer should not be able to open this view anyway
  };

  const toggleableRoles =
    toggleableRolesPerRole[currentSession.currentRoleName()];

  confirmModal({
    title: t('changePermissions'),
    body: (
      <div>
        {t('changePermissionsDescription')}
        <br />
        {availableRoleNames.map((roleName) => {
          return (
            <div>
              <input
                type="checkbox"
                name={roleName}
                defaultChecked={allowedRoleNames.includes(roleName)}
                onClick={() => toggle(roleName)}
                disabled={!toggleableRoles.includes(roleName)}
              />{' '}
              {t(`common.role:${roleName}`)}
            </div>
          );
        })}
      </div>
    ),
    actionLabel: 'Save',
    actionHandler: save,
  });
};

const getInitialModalComponent = (sportingEvent, unsetModalComponent) => {
  if (window.location.search) {
    let params = new URLSearchParams(window.location.search);
    // Upload video Modal
    if (
      params.get('upload') &&
      params.get('upload') === sportingEvent.id &&
      !sportingEvent.hasVideo() // Do not trigger when event already has video.
    ) {
      return uploadVideoModal(sportingEvent, unsetModalComponent);
    }
  }
  return null;
};

const getPropablyEnded = (sportingEvent) => {
  let oneOfTheseIsTrue = [];
  oneOfTheseIsTrue.push(getTiming(sportingEvent.get('scheduledAt')) === 'Past');
  oneOfTheseIsTrue.push(sportingEvent.probablyEnded());
  oneOfTheseIsTrue.push(sportingEvent.hasVideo());
  return oneOfTheseIsTrue.find((i) => i === true);
};

export const getLabel = (sportingEvent, t) => {
  const scheduledAt = moment(sportingEvent.get('scheduledAt'));
  const createdAt = moment(sportingEvent.get('createdAt'));
  const today = !!scheduledAt.isSame(moment(), 'day');
  const active = sportingEvent.started() && !sportingEvent.probablyEnded();
  const label = today
    ? `${t('common:daysAgo_0')}`
    : active
    ? 'LIVE'
    : moment().diff(createdAt, 'minutes') <= 15
    ? 'NEW'
    : '';

  return label;
};
