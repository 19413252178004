import React, { useEffect, useState } from 'react';

import api from '../../utils/ApiClient';
import { useTranslation } from 'react-i18next';
import { getTemplate, getTemplates } from '../../remotion/templateRegistry';
import { getClipConfig } from '../../remotion/clipRegistry';
import uuidv4 from 'uuid/v4';
import i18n from 'i18next';
import { InputCheckboxRadio } from '../../lib/InputCheckBoxRadio';
import { Session } from '../../domain/Session';

const createHighlightVideo = async ({
  name,
  templateId,
  content,
}: {
  name: string;
  templateId: string;
  content: any[];
}) => {
  return await api.post('highlights/v2/highlightVideos', {
    name,
    templateId,
    content,
  });
};

const getAvailableTemplates = async () => {
  const { data: templates } = await api.get('highlights/v2/templates');
  return (
    templates
      .map((template) => template.templateId)

      // make sure the template exists
      .filter((templateId) => !!getTemplate(templateId))
  );
};

const minimalNameLength = 3;

enum ViewStates {
  Init = 'INIT',
  Create = 'CREATE',
  Success = 'SUCCESS',
  Fail = 'FAIL',
}

export const HighlightsNewView: React.FC<{
  // templates: Template[];
  onSuccess: (any, clipTemplateBlockId: string) => void;
  onClose: (any) => void;
}> = ({ onSuccess, onClose }) => {
  const [view, setView] = useState('');
  const [name, setName] = useState('');
  const [templateId, setTemplateId] = useState<string>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [availableTemplates, setAvailableTemplates] = useState([]);
  const [content, setContent] = useState();
  const { t } = useTranslation('module.highlights');

  const templates = getTemplates();
  const currentLanguage = i18n?.language ?? 'en';

  useEffect(() => {
    (async () => {
      const _availableTemplates = await getAvailableTemplates();
      setAvailableTemplates(_availableTemplates);
      // Set highest template (order in templateRegistry) as selected one.
      setTemplateId(_availableTemplates[0]);
    })();
  }, []);

  const handleNameChange = (evt) => {
    setName(evt.target.value);
  };

  const onTemplateIdClick = (templateId) => () => {
    setTemplateId(templateId);
  };

  const isValidName = (name: string): boolean =>
    name.length >= minimalNameLength;

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setIsSubmitted(true);

    if (!isValidName(name) || templateId === undefined) {
      return;
    }

    setView(ViewStates.Create);

    try {
      const response = await createHighlightVideo({
        name,
        templateId,
        content: getInitialContent(name),
      });
      setView(ViewStates.Success);

      onSuccess(response.data);
    } catch (e) {
      setView(ViewStates.Fail);
      throw new Error(e);
    }
  };

  const getInitialContent = (name) => {
    // Set initial content when creating highlightVideo;
    let content = [];
    let currentSession = Session.current();
    if (templateId) {
      let selectedTemplate = templates[templateId];

      if (selectedTemplate?.intro) {
        const config = getClipConfig(selectedTemplate?.intro);
        if (selectedTemplate.intro === 'intro.club') {
          content.push({
            contentId: uuidv4(),
            options: {
              ...config.defaults,
              logo: [currentSession.currentMembershipLogo()],
              color1: currentSession?.currentMembershipAppearance?.primaryColor,
              color2:
                currentSession?.currentMembershipAppearance?.secondaryColor,
              title: name,
            },
            duration: config.duration,
            offset: config.offset,
            type: selectedTemplate?.intro,
          });
        } else {
          content.push({
            contentId: uuidv4(),
            options: config.defaults,
            duration: config.duration,
            offset: config.offset,
            type: selectedTemplate?.intro,
          });
        }
      }
      if (selectedTemplate?.outro) {
        const config = getClipConfig(selectedTemplate?.outro);
        content.push({
          contentId: uuidv4(),
          options: config.defaults,
          duration: config.duration,
          offset: config.offset,
          type: selectedTemplate?.outro,
        });
      }
    }
    return content;
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="modal-header">
        <h5 className="modal-title">{t('createHighlight')}</h5>
      </div>
      <div
        className={`form-group ${
          isSubmitted && !isValidName(name) ? 'is-invalid' : ''
        }`}
      >
        <label htmlFor="name">{t('videoName')}</label>
        <input
          type="text"
          className="form-control"
          id="name"
          onChange={handleNameChange}
          value={name}
        />
        {isSubmitted && !isValidName(name) && (
          <div className="invalid-feedback">{t('nameInvalid')}</div>
        )}
      </div>

      <div
        className={`form-group ${
          isSubmitted && !templateId ? 'is-invalid' : ''
        }`}
      >
        <label htmlFor="name">{t('selectTemplate')}</label>
        <div className="teaser-cards row">
          {Object.entries(templates).map(([label, template]) => {
            const isAvailable = availableTemplates.includes(
              template.templateId
            );
            const hidden = template?.private && !isAvailable;
            if (!hidden) {
              return (
                <div
                  key={template.templateId}
                  className={`template-card col-md-4 col-6 mb-2 ${
                    templateId === template.templateId ? 'selected' : ''
                  } ${isAvailable ? '' : 'disabled'}`}
                >
                  <div
                    key={template.templateId}
                    title={template.templateName}
                    onClick={
                      isAvailable
                        ? onTemplateIdClick(template.templateId)
                        : null
                    }
                    style={{
                      backgroundImage: `url(\"${template.imageUrl}\")`,
                    }}
                  >
                    <div>
                      <div className="checkbox__wrapper">
                        <InputCheckboxRadio
                          checked={templateId === template.templateId}
                        />
                      </div>
                      <h6>{template.templateName}</h6>
                      <p>
                        {template.description[currentLanguage] ??
                          template.description['en']}
                      </p>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
        {isSubmitted && !templateId && (
          <div className="invalid-feedback">{t('selectTemplate')}</div>
        )}
      </div>

      <div className="form-group actions">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={view === ViewStates.Init}
        >
          {t('createVideo')}
        </button>
        <button type="button" className="btn btn-link" onClick={onClose}>
          {t('common:cancel')}
        </button>
      </div>
    </form>
  );
};
