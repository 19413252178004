import React, { Component, useEffect, useState } from 'react';
import { asyncAction } from 'mobx-utils';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import ReactJWPlayer from 'react-jw-player';
import * as mobx from 'mobx';

import { Modal } from 'lib/Modal';
import { EventPublisher } from 'utils/EventPublisher';
import { ReactPlayer } from 'lib/ReactPlayer';
import Loading from '../../../lib/Loading';
import videoCollection from '../../../domain/Video';
import VideoAngleContextProvider from '../../../lib/VideoAngleContextProvider';
import { withTranslation } from 'react-i18next';

const Pagination = ({ playlistItems, activeItem, onClick, successMetric }) => {
  return playlistItems.map((item, n) => {
    return (
      <div
        key={n}
        className={`observation-circle float-left text-center clickable ${
          n === activeItem ? 'active' : ''
        } ${item.attributes[successMetric] ? 'success' : ''}`}
        onClick={() => onClick(n)}
      >
        {n + 1}
      </div>
    );
  });
};
const VideoPlayer = ({ playlistItems, playlistData, options }) => {
  const [activeItem, setActiveItem] = useState(0);

  const onReady = () => {
    window.jwplayer('playlistPlayer').on(
      'playlistItem',
      action(({ index }) => {
        setActiveItem(index);
      })
    );
  };
  let _multicam = // Only set multicam when more then 1 angle is available
    playlistData[activeItem]?.videoAngles?.length > 1
      ? playlistData[activeItem].videoAngles
      : null;
  return [
    <div className="row" key="player">
      <div className="col-12">
        <ReactPlayer
          playerId="playlistPlayer"
          playlistItems={playlistData}
          onReady={onReady}
          noDimensions
          multiCam={_multicam}
        />
      </div>
    </div>,
    <div className="row" key="pagination">
      <div className="col-12">
        <Pagination
          playlistItems={playlistItems}
          activeItem={activeItem}
          successMetric={options.successMetric}
          onClick={(n) => {
            window.jwplayer('playlistPlayer').playlistItem(n);
          }}
        />
      </div>
    </div>,
  ];
  // <VideoPagination
  //     totalItems={playlistData.length}
  //     activeItem={activeItem}
  //     classNames={classNames}
  //
};

const VideoPlayerWrapper = ({
  sportingEvent,
  playlistData,
  playlistItems,
  onClose,
  options,
}) => {
  const [loading, setLoading] = useState(true);
  const [playlist, setPlaylist] = useState(true);

  useEffect(() => {
    (async () => {
      await videoCollection.fetchIfEmpty();
      let _playlist = playlistData.map((playlistData) => {
        let index =
          playlistData.videoAngles.findIndex(
            (va) => va.videoId === sportingEvent.mainVideoId()
          ) ?? 0;
        let _video = videoCollection.get(
          playlistData.videoAngles[index].videoId
        );
        let startTime = playlistData.videoAngles[index].startTime;
        let endTime = playlistData.videoAngles[index].endTime;
        let videoFragment = _video.getVideoFragment(startTime, endTime);
        return {
          file: _video.getVideoFragmentUrl(startTime, endTime),
          videoFragment,
          videoAngles: playlistData.videoAngles.map((a) => a.videoId),
        };
      });
      setPlaylist(_playlist);
      setLoading(false);
    })();
  }, [playlistData]);

  return (
    <Modal size="lg" onCloseClick={onClose}>
      {loading && <Loading />}
      {!loading && (
        <VideoPlayer
          playlistData={playlist}
          playlistItems={playlistItems}
          options={options}
        />
      )}
    </Modal>
  );
};

const Dashboard = withTranslation('module.dashboard')(
  observer(
    class Dashboard extends Component {
      // LayoutComponent
      // Layout = <Layout videoLoader/>
      //

      constructor() {
        super();

        this.playlist = observable(false);
        this.showPlayer = observable(false);
        this.noVideoModal = observable(false);
      }

      loadVideo = asyncAction(function* (
        report,
        cell,
        {
          successMetric,
          durationBefore,
          durationAfter,
          observationId = null,
        } = {}
      ) {
        if (cell.count > 0 || cell.duration > 0 || observationId !== null) {
          let playlistItems = yield report.getVideoPlaylist(
            cell.path,
            durationBefore,
            durationAfter
          );
          if (observationId !== null) {
            playlistItems = [
              playlistItems.find(
                (playlistItem) => playlistItem.observationId === observationId
              ),
            ];
          }
          this.playlist.set({
            playlistItems,
            options: { successMetric },
          });
          this.showPlayer.set(true);

          EventPublisher.dispatch('DASHBOARD_VIDEO_VIEWED');
        }
      }).bind(this);

      render() {
        const {
          layoutComponent: LayoutComponent,
          report,
          sportingEvent,
          includeVideo,
          reportState,
        } = this.props;
        const onClick =
          (sportingEvent.hasVideo() || sportingEvent.isLive()) && includeVideo
            ? (cell, options) => this.loadVideo(report, cell, options)
            : (cell, options) =>
                action(() => {
                  if (cell.count > 0 || cell.duration > 0) {
                    this.noVideoModal.set(true);
                  }
                })();

        onClick.setDuration = (durationBefore, durationAfter) => {
          return (cell, { successMetric } = {}) => {
            return onClick(cell, {
              successMetric,
              durationBefore,
              durationAfter,
            });
          };
        };

        const homeTeamReport = report.c(`team_id:${sportingEvent.homeTeam.id}`);
        const awayTeamReport = report.c(`team_id:${sportingEvent.awayTeam.id}`);

        const { playlistItems, options } = mobx.toJS(this.playlist.get());

        const playlistData = playlistItems?.map((item) => {
          return {
            file: item.sources[0],
            title: item.description,
            videoAngles: item.videoAngles,
          };
        });
        const t = this.props.t;

        console.log(report);

        return (
          <div>
            <LayoutComponent
              onClick={onClick}
              homeTeam={sportingEvent.homeTeam}
              awayTeam={sportingEvent.awayTeam}
              homeTeamReport={homeTeamReport}
              awayTeamReport={awayTeamReport}
              reportState={reportState}
              sportingEvent={sportingEvent}
            />
            {includeVideo && this.showPlayer.get() && (
              <VideoAngleContextProvider>
                <VideoPlayerWrapper
                  sportingEvent={sportingEvent}
                  playlistItems={playlistItems}
                  playlistData={playlistData}
                  options={options}
                  onClose={action(() => {
                    this.showPlayer.set(false);
                  })}
                />
              </VideoAngleContextProvider>
            )}
            {this.noVideoModal.get() && (
              <Modal
                onCloseClick={action(() => {
                  this.noVideoModal.set(false);
                })}
              >
                <div className="modal-header">
                  <h5>{t('noVideoAvailable')}</h5>
                </div>
                <div className="modal-body">
                  <p>{t('noVideoAvailableBody')}</p>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-primary"
                    onClick={action(() => {
                      this.noVideoModal.set(false);
                    })}
                  >
                    {t('common:close')}
                  </button>
                </div>
              </Modal>
            )}
          </div>
        );
      }
    }
  )
);
//
// class KorfballLayout extends Component {
//     render() {
//         const Library = this.props.elementLibrary;
//         <div>
//             <Library.Counter cell={homeAttack.c("blaat")} />
//         </div>
//     }
// }
//
//
//
// const KorfballDashboard = ({sportingEvent}) => {
//     return <Dashboard layout={KorfballLayout} report={sportingEvent.getReport('korfball')} />;
// };

export { Dashboard };
