import { joinResourceGroup } from '../utils';
import { Session } from '../../../../domain/Session';
import { useAssignmentContext } from '../../assignments/assignmentContext';
import { useEffect, useState } from 'react';

import { TaskModal } from '../../assignments/TaskModal';

import { isDev } from '../../../../lib/dev';
import { getSportingEvents } from '../../../highlights/highlightsDetail';
import sportingEventCollection from '../../../../domain/SportingEvent';

export const ReviewModal = ({
  resourceGroupId,
  practiceId,
  onClose,
  learningGroupId,
  learningLineId,
}) => {
  const currentSession = Session.current();
  const assignmentContext = useAssignmentContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (resourceGroupId && practiceId) {
      (async () => {
        await startReview();
      })();
    }
    return () => onClose();
  }, [resourceGroupId, practiceId]);

  const startReview = async () => {
    if (!currentSession.isMemberOf(learningGroupId)) {
      isDev() && console.log('join learningGroup', learningGroupId);
      await joinResourceGroup(
        learningGroupId,
        currentSession.currentResourceGroup().id
      );
    }

    if (!currentSession.isMemberOf(resourceGroupId)) {
      isDev() && console.log('join Trainee', resourceGroupId);
      await joinResourceGroup(resourceGroupId, learningGroupId);
    }

    assignmentContext.setLearningLineId(learningLineId);
    assignmentContext.setIsTeacher(true);
    assignmentContext.refresh();

    setLoading(false);
  };

  if (
    resourceGroupId &&
    practiceId &&
    !loading &&
    assignmentContext.assignments?.length > 0
  ) {
    return (
      <TaskModal
        currentPracticeId={practiceId}
        onCloseClick={onClose}
      ></TaskModal>
    );
  } else {
    return null;
  }
};
